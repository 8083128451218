<template>
    <div>
        <b-card
            class="cc-content cc-content-card"
            v-if="$can('read', 'period')"
        >
            <b-row>
                <b-col>
                    <h1>{{ $t("company_period_settings") }}</h1>
                </b-col>
            </b-row>
            <b-row class="mt-5">
                <b-col cols="2">
                    {{ $t("companyGeneral_periods") }}
                </b-col>
                <b-col>
                    <company-revenue-table></company-revenue-table>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import CompanyRevenueTable from "@/components/company/CompanyRevenueTable.vue";

export default {
    components: { CompanyRevenueTable },
};
</script>
