<template>
    <div>
        <b-row>
            <b-col cols="12" md="8" class="p-2">
                <b-card>
                    <template #header>
                        <div class="ml-2">
                            <p class="text-mini text-gray-500 m-0">
                                {{ $t("in_ton") }}
                            </p>
                            <h2>{{ $t("emissions_per_site") }}</h2>
                        </div>
                    </template>

                    <transition mode="out-in" name="slow-fade">
                        <D3HorizontalBar
                            :data="siteData"
                            chartKey="siteData"
                            labelKey="site_name"
                            idKey="site_id"
                            @bar-clicked="$emit('site-clicked', $event)"
                            v-if="!loading"
                        ></D3HorizontalBar>

                        <b-skeleton-img v-else />
                    </transition>
                </b-card>
            </b-col>
            <b-col cols="12" md="4" class="p-2">
                <stats-card
                    type=""
                    icon="fas fa-smoke stats-card__icon"
                    iconClasses="text-white"
                    class="bg-dashboard-2 text-white px-4 py-2"
                >
                    <h3 class="card-title text-white mb-1 light">
                        {{ $t("total_emissions") }}
                    </h3>
                    <div>
                        <span class="h1 font-weight-bold mb-0 text-white">
                            {{ total }}
                        </span>
                        <span
                            class="text-nowrap text-tiny ml-3 text-white light"
                        >
                            {{ $t("ton_co2e") }}
                        </span>
                    </div>

                    <template slot="footer">
                        <div class="flex justify-content-between">
                            <span
                                class="text-nowrap color-success light"
                            ></span>
                            <span class="text-nowrap">
                                <b-button
                                    size="sm"
                                    variant="outline-light"
                                    @click="$router.push('/data-input')"
                                >
                                    {{ $t("view_data") }}
                                    <i class="fas fa-chevron-right ml-2"></i>
                                </b-button>
                            </span>
                        </div>
                    </template>
                </stats-card>

                <b-card>
                    <template #header>
                        <div class="ml-2">
                            <p class="text-mini text-gray-500 m-0">
                                {{ $t("in_ton") }}
                            </p>
                            <h2>{{ $t("emissions_per_bu") }}</h2>
                        </div>
                    </template>

                    <transition mode="out-in" name="slow-fade">
                        <template v-if="!loading">
                            <D3Pie
                                :data="buData"
                                chartKey="buData"
                                labelKey="business_unit_name"
                                idKey="business_unit_id"
                                colourKey="business_unit_colour"
                                @arc-clicked="handleChartClicked"
                            ></D3Pie>
                        </template>

                        <b-skeleton-img v-else />
                    </transition>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import D3HorizontalBar from "@/components/charts/D3HorizontalBar.vue";
import D3Pie from "@/components/charts/D3Pie";
import InsightsMixin from "../mixins/InsightsMixin.vue";

export default {
    mixins: [InsightsMixin],
    components: {
        D3HorizontalBar,
        D3Pie,
    },
    props: {
        sites: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            hoverData: null,
            buData: [],
            siteData: [],
            loading: true,
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            this.getTotal();
            this.loading = true;
            const siteData = this.getSiteData();
            const buData = this.getBuData();
            await Promise.all([siteData, buData]);
            this.loading = false;
        },
    },
};
</script>
