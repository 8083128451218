<template>
    <b-modal id="modal-add-site" :title="title" hide-footer size="lg">
        <b-form @submit.prevent="save">
            <div class="row">
                <div class="col">
                    <b-form-group>
                        <label for="input-new-site-name">
                            {{ $t("label_name") }} (*)
                        </label>
                        <b-input
                            id="input-new-site-name"
                            required
                            v-model="data.name"
                        ></b-input>
                    </b-form-group>

                    <b-form-group>
                        <label for="input-new-site-fte">
                            {{ $t("label_fte") }}
                        </label>
                        <b-input
                            id="input-new-site-fte"
                            v-model="data.fte"
                        ></b-input>
                    </b-form-group>

                    <b-form-group>
                        <label for="input-new-site-area">
                            {{ $t("label_surface_m2") }}
                        </label>
                        <b-input
                            id="input-new-site-area"
                            v-model="data.surfaceArea"
                        ></b-input>
                    </b-form-group>
                </div>

                <div class="col">
                    <b-form-group>
                        <label for="select-new-site-sitegroup">
                            {{ $t("label_group") }}
                        </label>
                        <cc-select
                            id="select-new-site-sitegroup"
                            v-model="data.siteGroupId"
                            :options="groupOptions"
                        ></cc-select>
                    </b-form-group>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12">
                    <h2>{{ $t("label_address") }}</h2>
                </div>
                <div class="col">
                    <b-form-group>
                        <label for="input-new-site-address-addressLine1">
                            {{ $t("label_street") }}
                        </label>
                        <b-input
                            id="input-new-site-address-addressLine1"
                            v-model="data.address.addressLine1"
                        ></b-input>
                    </b-form-group>
                    <b-form-group>
                        <label for="input-new-site-address-postalcode">
                            {{ $t("label_zip") }}
                        </label>
                        <b-input
                            id="input-new-site-address-postalcode"
                            v-model="data.address.postcode"
                        ></b-input>
                    </b-form-group>
                </div>

                <div class="col">
                    <b-form-group>
                        <label for="input-new-site-address-city">
                            {{ $t("label_city") }}
                        </label>
                        <b-input
                            id="input-new-site-address-city"
                            v-model="data.address.city"
                        ></b-input>
                    </b-form-group>
                    <b-form-group>
                        <label for="select-new-site-address-country">
                            {{ $t("country") }} (*)
                        </label>
                        <cc-select
                            id="select-new-site-address-country"
                            required
                            v-model="data.address.countryCode"
                            :options="countrySelectOptions"
                        ></cc-select>
                    </b-form-group>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col text-right">
                    <b-button
                        v-if="!isNewSite && $can('delete', 'site')"
                        variant="danger"
                        @click="handleDelete(site.id)"
                        >{{ $t("delete_site") }}</b-button
                    >
                    <b-button
                        class="ml-2"
                        @click="$bvModal.hide('modal-add-site')"
                        >{{ $t("cancel") }}
                    </b-button>
                    <b-button class="ml-2" variant="primary" type="submit"
                        >{{ $t("save") }}
                    </b-button>
                </div>
            </div>
        </b-form>
    </b-modal>
</template>

<script>
import siteApi from "@/api/site.js";
import { getCountrySelectOptions } from "@/util/countries.js";
import swal from "sweetalert2";

const initialData = {
    name: "",
    fte: "",
    surfaceArea: "",
    siteGroupId: -1,
    contact: {
        name: "",
        lastName: "",
        email: "",
        save: false,
    },
    address: {
        addressLine1: "",
        postcode: "",
        city: "",
        countryCode: "",
    },
};

export default {
    props: {
        site: {
            type: Object,
            required: false,
            default: null,
        },
        siteGroups: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            data: {},
        };
    },
    computed: {
        groupOptions() {
            const groups = this.siteGroups.map((sg) => ({
                text: sg.name,
                value: sg.id,
            }));
            groups.unshift({ text: "No group", value: -1 });
            return groups;
        },
        isNewSite() {
            return !this.site || this.site.id <= 0;
        },
        title() {
            return this.isNewSite ? "New site" : "Edit site";
        },
        countrySelectOptions() {
            return getCountrySelectOptions();
        },
    },
    created() {
        // Create the data structure on first create
        // eslint-disable-next-line no-undef
        this.data = structuredClone(initialData);
        this.init();
    },
    methods: {
        init() {
            if (this.isNewSite) {
                // eslint-disable-next-line no-undef
                this.data = structuredClone(initialData);
            } else {
                this.data.name = this.site.name;
                this.data.fte = this.site.fte;
                this.data.surfaceArea = this.site.surface_area;
                this.data.siteGroupId = this.site.group_id;
                this.data.address = {
                    addressLine1: this.site.address_line_1,
                    postcode: this.site.postcode,
                    city: this.site.city,
                    countryCode: this.site.country_code,
                };
            }
        },
        save() {
            let { name, fte, surfaceArea, siteGroupId, contact, address } =
                this.data;
            let params = {
                name,
                fte,
                surfaceArea,
                siteGroupId,
                contact,
                address,
            };

            if (this.isNewSite) this.addSite(params);
            else this.updateSite(params);
        },
        async addSite(params) {
            try {
                await siteApi.addSite(params);
                this.$emit("update");
                this.$bvModal.hide("modal-add-site");
                this.init();
            } catch (error) {
                console.log(error);
            }
        },
        async updateSite(params) {
            try {
                await siteApi.updateSite(this.site.id, params);
                this.$emit("update");
                this.$bvModal.hide("modal-add-site");
                this.init();
            } catch (error) {
                console.log(error);
            }
        },
        async deleteSite(site) {
            try {
                await siteApi.deleteSite(site);
                this.$emit("update");
                this.$bvModal.hide("modal-add-site");
                this.init();
            } catch (error) {
                console.log(error);
            }
        },
        async handleDelete(site) {
            const result = await swal.fire({
                title: "Delete site?",
                html: `
                    <div class="text-left light">
                        <p>Are you sure you want to delete ${this.data.name}?</p>
                    </div>

                `,
                icon: "warning",
                confirmButtonText: "Yes, delete",
                confirmButtonColor: "#f5365c",
                showCancelButton: true,
                cancelButtonText: "Cancel",
            });

            if (result.isConfirmed) this.deleteSite(site);
        },
    },
    watch: {
        site() {
            this.init();
        },
    },
};
</script>
