<template>
    <b-form-group
        id="select-tags"
        :label="$t('user_form_label_allowed_tags')"
        :description="$t('user_form_label_allowed_tags_description')"
    >
        <b-form-checkbox
            v-model="all"
            name="allow-all-button"
            switch
            class="mb-2"
            @change="handleChange"
        >
            {{ $t("form_checkbox") }}
        </b-form-checkbox>
        <b-form-select
            v-model="selected"
            text-field="title"
            value-field="id"
            :options="tags"
            multiple
            :select-size="8"
            v-if="!all"
            @change="handleChange"
            required
        ></b-form-select>
    </b-form-group>
</template>

<script>
export default {
    props: {
        userSelected: {
            type: Array,
            required: true,
        },
        userAll: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            tags: this.$store.getters.getTags(),
            selected: [],
            all: false,
        };
    },
    mounted() {
        this.selected = this.userSelected;
        this.all = this.userAll;
    },
    methods: {
        handleChange() {
            this.$emit("update", { selected: this.selected, all: this.all });
        },
    },
};
</script>
