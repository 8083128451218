<template>
    <div>
        <b-card class="cc-content cc-content-card">
            <b-row>
                <b-col>
                    <h1>{{ $t("companyStructure_header") }}</h1>
                </b-col>
                <b-col class="text-right">
                    <b-button
                        variant="primary"
                        @click="showSiteGroupModal(null)"
                        v-if="$can('create', 'site_group')"
                    >
                        {{ $t("companyStructure_new_group") }}
                    </b-button>
                    <b-button
                        variant="primary"
                        @click="showSiteModal(null)"
                        v-if="$can('create', 'site')"
                    >
                        {{ $t("companyStructure_new_site") }}
                    </b-button>
                </b-col>
            </b-row>

            <div class="mt-7">
                <accordion-list
                    v-for="site in structure.sites"
                    :key="'site' + site.id"
                    :item="site"
                    type="static"
                    v-on:more="showSiteModal"
                    style="padding: 20px 20px 0 10px"
                >
                    <template>
                        <span class="pr-2">{{
                            flagMapper[site.country_code]
                        }}</span>
                    </template>
                </accordion-list>

                <accordion
                    v-for="siteGroup in structure.siteGroups"
                    :key="'sitegroup' + siteGroup.id"
                    :item="siteGroup"
                    v-on:more="showSiteGroupModal"
                >
                    <template>
                        <accordion-list
                            v-for="site in siteGroup.sites"
                            :key="'site' + site.id"
                            :item="site"
                            v-on:more="showSiteModal"
                        >
                            <template>
                                <span class="pr-2">{{
                                    flagMapper[site.country_code]
                                }}</span>
                            </template>
                        </accordion-list>
                    </template>
                </accordion>
            </div>
        </b-card>

        <b-card class="cc-content cc-content-card">
            <company-business-units
                v-if="$can('read', 'business_unit')"
            ></company-business-units>
        </b-card>

        <site-modal
            :siteGroups="structure.siteGroups"
            @update="load"
            :site="site"
        />
        <site-group-modal @update="load" :siteGroup="siteGroup" />
    </div>
</template>

<script>
import companyApi from "@/api/company.js";
import Accordion from "@/components/accordion/Accordion.vue";
import AccordionList from "@/components/accordion/AccordionList.vue";
import CompanyBusinessUnits from "@/components/company/CompanyBusinessUnits";
import SiteGroupModal from "@/components/company/SiteGroupModal.vue";
import SiteModal from "@/components/company/SiteModal.vue";
import * as types from "@/store/types.js";
import { flagMapper } from "@/util/countries.js";
export default {
    components: {
        SiteModal,
        SiteGroupModal,
        Accordion,
        AccordionList,
        CompanyBusinessUnits,
    },
    data() {
        return {
            structure: {
                sites: [],
                siteGroups: [],
            },
            site: {},
            siteGroup: {},
        };
    },
    mounted() {
        this.load();
    },
    computed: {
        flagMapper() {
            return flagMapper;
        },
    },
    methods: {
        async load() {
            try {
                const result = await companyApi.getStructure();
                this.structure = result.data;
                this.updateStoreSites(this.structure);
            } catch (error) {
                console.log(error);
            }
        },
        updateStoreSites(data) {
            const GroupSites = [
                ...new Set(data.siteGroups.flatMap((obj) => obj.sites.flat())),
            ];
            const sites = data.sites;
            const allSites = [...GroupSites, ...sites];
            this.$store.dispatch(types.SET_DATA, {
                name: "sites",
                data: allSites,
            });
        },

        showSiteGroupModal(siteGroup) {
            if (!this.$can("update", "site_group")) return;
            this.siteGroup = siteGroup;
            this.$bvModal.show("modal-add-sitegroup");
        },
        showSiteModal(site) {
            if (!this.$can("update", "site")) return;
            this.site = site;
            this.$bvModal.show("modal-add-site");
        },
    },
};
</script>
