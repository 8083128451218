<template>
    <b-modal
        id="modal-bu"
        :title="title"
        :ok-title="$t('save')"
        ok-only
        @ok="$emit('save')"
        size="lg"
        :visible="showModal"
        @close="$emit('close')"
        @cancel="$emit('close')"
        @hidden="$emit('close')"
    >
        <div v-if="showModal">
            <div class="row">
                <div class="col">
                    <fieldset>
                        <label for="bu-name">
                            {{ $t("label_name") }}
                        </label>
                        <b-input id="bu-name" v-model="bu.name"></b-input>
                    </fieldset>
                    <fieldset class="mt-4">
                        <label for="bu-colour">
                            {{ $t("label_colour") }}
                        </label>
                        <div>
                            <div
                                class="colour-preview shadow-sm"
                                :class="
                                    bu.colour === colour
                                        ? 'colour-preview--selected'
                                        : ''
                                "
                                v-for="colour in colours"
                                :key="colour"
                                :style="`background-color: ${colour};`"
                                @click="colourPicked(colour)"
                            ></div>
                        </div>
                    </fieldset>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col">
                    <span
                        class="badge badge-pill"
                        :style="`color: ${value.colour}; background-color: ${value.colour}10;`"
                    >
                        <span>{{
                            value.name === "" ? $t("enter_value") : value.name
                        }}</span>
                    </span>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        showModal: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            bu: {
                name: "",
                colour: "",
            },
            colours: [
                "#01CAAB",
                "#FFD23F",
                "#EE4266",
                "#1C4577",
                "#D0CFEC",
                "#08B1C7",
                "#F7D1CD",
                "#B87B99",
                "#1EAE47",
                "#959537",
            ],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.prefillFields;
        },
        colourPicked(colour) {
            this.bu.colour = colour;
        },
    },
    watch: {
        businessUnit: {
            handler() {
                this.init();
            },
        },
        value: {
            handler(newValue) {
                this.bu = newValue;
            },
            deep: true,
        },
    },
};
</script>
