<template>
    <div>
        <b-card class="cc-content cc-content-card">
            <b-row>
                <b-col>
                    <h1>{{ $t("Tags") }}</h1>
                </b-col>
            </b-row>

            <div class="mt-6">
                <b-table-simple responsive>
                    <b-thead>
                        <b-tr>
                            <b-th>{{ $t("tag") }}</b-th>
                            <b-th>{{ $t("times_used") }}</b-th>
                            <b-th></b-th>
                            <b-th>{{ $t("label_actions") }}</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="tag in tags" :key="tag.title">
                            <b-td>
                                <span
                                    class="badge tag cursor-pointer"
                                    @click="setFilterAndNavigate(tag)"
                                >
                                    {{ tag.title }}
                                </span>
                            </b-td>
                            <b-td>{{ tag.count }}</b-td>
                            <b-td></b-td>
                            <b-td width="10%">
                                <b-button
                                    :id="'edit_button' + tag.id"
                                    size="sm"
                                    variant="outline-primary"
                                    icon
                                    @click="handleEdit(tag)"
                                >
                                    <i class="fas fa-pencil"></i>
                                </b-button>
                                <b-tooltip
                                    :target="'edit_button' + tag.id"
                                    triggers="hover"
                                >
                                    {{ $t("edit") }}
                                </b-tooltip>
                                <b-button
                                    :id="'delete_button' + tag.id"
                                    size="sm"
                                    variant="outline-danger"
                                    icon
                                    @click="handleDelete(tag)"
                                >
                                    <i class="fas fa-trash"></i>
                                </b-button>
                                <b-tooltip
                                    :target="'delete_button' + tag.id"
                                    triggers="hover"
                                >
                                    {{ $t("delete") }}
                                </b-tooltip>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td colspan="100%">
                                <p
                                    class="text-small text-gray-500 cursor-pointer light"
                                    @click="handleAddTag"
                                    v-if="$can('create', 'tag')"
                                >
                                    <i class="fa-regular fa-circle-plus"></i>
                                    {{ $t("add_tag") }}
                                </p>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
        </b-card>
    </div>
</template>

<script>
import tagsApi from "@/api/tags";

import * as types from "@/store/types";
import swal from "sweetalert2";

export default {
    name: "CCTags",
    data() {
        return {
            tags: [],
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.getTags();
        },
        async getTags() {
            try {
                const response = await tagsApi.listAll();
                this.tags = response.data;
                this.$store.dispatch(types.SET_DATA, {
                    name: "tags",
                    data: this.tags,
                });
            } catch (error) {
                console.log(error);
            }
        },
        async editTag(tag, val) {
            try {
                await tagsApi.edit({ title: val }, tag.id);
                this.init();
            } catch (error) {
                console.log(error);
            }
        },
        async deleteTag(tag) {
            try {
                await tagsApi.deleteAdmin(tag.id);
                this.init();
            } catch (error) {
                console.log(error);
            }
        },
        async createTag(title) {
            try {
                await tagsApi.create({ title });
                this.init();
            } catch (error) {
                console.log(error);
            }
        },
        async handleDelete(tag) {
            const result = await swal.fire({
                title: this.$t("tags_handleDelete_title"),
                html: `
                    <div class="text-left light">
                        <p>${this.$t("tags_handleDelete_html_question")}</p>
                        <p><span class="badge tag">${tag.title}</span></p>
                        <p>
                        ${this.$t("tags_handleDelete_html_applied", {
                            tag: tag.count,
                        })}
                        </p>
                    </div>

                `,
                icon: "warning",
                confirmButtonText: this.$t(
                    "tags_handleDelete_confirmButtonText"
                ),
                confirmButtonColor: "#f5365c",
                showCancelButton: true,
                cancelButtonText: this.$t("cancel"),
            });

            if (result.isConfirmed) this.deleteTag(tag);
        },
        async handleEdit(tag) {
            const result = await swal.fire({
                title: this.$t("tags_handleEdit_title"),
                html: ` <p class="light">
                            ${this.$t("tags_handleEdit_html")} <strong>${
                    tag.title
                }</strong>.
                    </p>`,
                input: "text",
                inputAttributes: { maxlength: 25 },
                inputPlaceholder: this.$t("new_name"),
                confirmButtonText: this.$t("save"),
                showCancelButton: true,
                cancelButtonText: this.$t("cancel"),
            });

            if (result.isConfirmed && result.value)
                this.editTag(tag, result.value);
        },
        async handleAddTag() {
            const result = await swal.fire({
                title: this.$t("tags_handleAddTag_title"),
                html: `<p class="light">${this.$t(
                    "tags_handleAddTag_html"
                )}</p>`,
                input: "text",
                inputAttributes: { maxlength: 25 },
                inputPlaceholder: this.$t("new_name"),
                confirmButtonText: this.$t("save"),
                showCancelButton: true,
                cancelButtonText: this.$t("cancel"),
            });

            if (result.isConfirmed && result.value)
                this.createTag(result.value);
        },
        async setFilterAndNavigate(tag) {
            await this.$store.dispatch(types.SET_FILTER, { tag: tag.id });
            this.$router.push({ name: "DataInput", query: { expandAll: "1" } });
        },
    },
};
</script>
