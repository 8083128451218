<template>
    <b-modal
        id="modal-team-user"
        :title="`${user.first_name} ${user.last_name} - ${user.email}`"
        :ok-title="$t('save')"
        @ok="handleSave"
        size="md"
        :visible="showModal"
        @close="$emit('close')"
        @cancel="$emit('close')"
        @hidden="$emit('close')"
    >
        <div v-if="user">
            <b-form>
                <sites-multiselect
                    :userSelected="user?.constraints?.site_ids || []"
                    :userAll="!!user.access_all_sites"
                    @update="
                        ({ selected, all }) => {
                            this.selectSites = selected;
                            this.allSites = all;
                        }
                    "
                />
                <business-units-multiselect
                    :userSelected="user?.constraints?.business_unit_ids || []"
                    :userAll="!!user.access_all_business_units"
                    @update="
                        ({ selected, all }) => {
                            this.selectBusinessUnits = selected;
                            this.allBusinessUnits = all;
                        }
                    "
                />
                <tags-multiselect
                    :userSelected="user?.constraints?.tag_ids || []"
                    :userAll="!!user.access_all_tags"
                    @update="
                        ({ selected, all }) => {
                            this.selectTags = selected;
                            this.allTags = all;
                        }
                    "
                />
                <b-form-group
                    id="form-input"
                    :label="$t('role')"
                    description="Which permissions do you want to give this user?"
                >
                    <cc-select
                        v-model="user.role"
                        :options="roles"
                        required
                    ></cc-select>
                </b-form-group>
                <p class="m-0 text-tiny text-gray-500">
                    <span class="text-gray-600"> {{ $t("form_admin") }} </span>:
                    {{ $t("form_admin_text") }}
                </p>
                <p class="m-0 text-tiny text-gray-500">
                    <span class="text-gray-600"> {{ $t("form_editor") }} </span
                    >:
                    {{ $t("form_editor_text") }}
                </p>
                <p class="m-0 text-tiny text-gray-500">
                    <span class="text-gray-600">
                        {{ $t("form_contributor") }} </span
                    >: {{ $t("form_contributor_text") }}
                </p>
                <p class="m-0 text-tiny text-gray-500">
                    <span class="text-gray-600"> {{ $t("form_auditor") }} </span
                    >: {{ $t("form_auditor_text") }}
                </p>
                <p class="m-0 text-tiny text-gray-500">
                    <span class="text-gray-600">
                        {{ $t("form_read_only") }} </span
                    >: {{ $t("form_read_only_text") }}
                </p>
            </b-form>
        </div>
    </b-modal>
</template>

<script>
import companyApi from "@/api/company";
import BusinessUnitsMultiselect from "./components/BusinessUnitsMultiselect.vue";
import SitesMultiselect from "./components/SitesMultiselect.vue";
import TagsMultiselect from "./components/TagsMultiselect.vue";

export default {
    components: { SitesMultiselect, BusinessUnitsMultiselect, TagsMultiselect },
    props: {
        editUser: {
            type: Object,
            required: true,
        },
        showModal: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            sites: this.$store.getters.getSites(),
            businessUnits: this.$store.getters.getBusinessUnits(),
            tags: this.$store.getters.getTags(),
            user: this.editUser,
            roles: ["admin", "editor", "contributor", "auditor", "read-only"],
            selectSites: [],
            selectBusinessUnits: [],
            selectTags: [],
            allSites: false,
            allBusinessUnits: false,
            allTags: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.user = { ...this.editUser };
            this.allSites = !!this.editUser.access_all_sites;
            this.allBusinessUnits = !!this.editUser.access_all_business_units;
            this.allTags = !!this.editUser.access_all_tags;
            this.selectTags = this.editUser.constraints?.tag_ids || [];
            this.selectSites = this.editUser.constraints?.site_ids || [];
            this.selectBusinessUnits =
                this.editUser.constraints?.business_unit_ids || [];
        },
        async handleSave() {
            await this.updateUserRestrictions();
            this.$emit("saved");
        },
        async updateUserRestrictions() {
            try {
                // Make sure that no sites are selected if the user has access to all sites
                // Same for business units
                if (this.allSites) this.selectSites = [];
                if (this.allBusinessUnits) this.selectBusinessUnits = [];
                if (this.allTags) this.selectTags = [];

                await companyApi.updateUserRestrictions({
                    ...this.user,
                    access_all_sites: this.allSites,
                    access_all_business_units: this.allBusinessUnits,
                    access_all_tags: this.allTags,
                    constraints: {
                        site_ids: this.selectSites,
                        business_unit_ids: this.selectBusinessUnits,
                        tag_ids: this.selectTags,
                    },
                });
            } catch (error) {
                console.log(error);
            }
        },
    },
    watch: {
        showModal: {
            handler(val) {
                if (val) {
                    this.init();
                }
            },
        },
    },
};
</script>
