<template>
    <b-modal
        id="modal-user-form"
        :title="
            editPeriod === null ? $t('periodForm_add') : $t('periodForm_edit')
        "
        hide-footer
        @hidden="$emit('close')"
        v-model="modal"
        size="lg"
    >
        <b-form @submit.prevent="save" @keydown.enter.prevent="save">
            <b-row>
                <b-col>
                    <label>{{ $t("label_period") }}</label>
                    <CcInput v-model="period.name" :required="true" />
                </b-col>
            </b-row>

            <b-row class="mt-4">
                <b-col>
                    <label>{{ $t("start_date") }}</label>
                    <b-form-datepicker
                        id="start-datepicker"
                        v-model="period.start_date"
                        required
                    ></b-form-datepicker>
                </b-col>
                <b-col>
                    <label>{{ $t("end_date") }}</label>
                    <b-form-datepicker
                        id="end-datepicker"
                        v-model="period.end_date"
                        required
                        :disabled="interval !== 'custom'"
                    ></b-form-datepicker>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col>
                    <b-button-group>
                        <b-button
                            @click="interval = 'yearly'"
                            :variant="interval === 'yearly' ? 'primary' : ''"
                        >
                            {{ $t("yearly") }}
                        </b-button>
                        <b-button
                            @click="interval = 'quarterly'"
                            :variant="interval === 'quarterly' ? 'primary' : ''"
                        >
                            {{ $t("quarterly") }}
                        </b-button>
                        <b-button
                            @click="interval = 'custom'"
                            :variant="interval === 'custom' ? 'primary' : ''"
                        >
                            {{ $t("custom_period") }}
                        </b-button>
                    </b-button-group>
                </b-col>
            </b-row>

            <b-row class="mt-4">
                <b-col>
                    <label>{{ $t("label_revenue_euro") }}</label>
                    <CcInput type="number" v-model="period.revenue" />
                </b-col>
            </b-row>

            <b-row class="mt-4" v-if="editPeriod === null">
                <b-col>
                    <b-form-checkbox
                        v-model="duplicateFromPreviousYear"
                        name="check-button"
                        switch
                    >
                        {{ $t("periodForm_copyEmissionFactors_checkbox") }}
                    </b-form-checkbox>
                </b-col>
            </b-row>

            <Transition name="slide-down" mode="out-in">
                <b-row
                    class="mt-4"
                    v-if="duplicateFromPreviousYear && !editPeriod"
                >
                    <b-col>
                        <b-form-checkbox
                            v-model="duplicate.values"
                            name="values-checkbox"
                            switch
                        >
                            {{ $t("periodForm_copyValues") }}
                        </b-form-checkbox>
                        <b-form-checkbox
                            v-model="duplicate.notes"
                            name="notes-checkbox"
                            switch
                        >
                            {{ $t("periodForm_copyDescriptions") }}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </Transition>

            <b-row class="mt-4">
                <b-col align="end" class="mb-4">
                    <b-button
                        key="close"
                        type="outline-primary"
                        @click.prevent="$emit('close')"
                        class="mr-2"
                    >
                        <span class="btn-inner--text">{{ $t("cancel") }}</span>
                    </b-button>
                    <b-button type="submit" variant="primary">
                        {{ $t("save") }}
                    </b-button>
                    <p
                        v-if="errorMessage !== ''"
                        class="text-tiny color-error mt-4"
                    >
                        {{ $t("error") }}: {{ errorMessage }}
                    </p>
                </b-col>
            </b-row>
        </b-form>
    </b-modal>
</template>

<script>
import periodApi from "@/api/period";

const periodForm = {
    name: "",
    start_date: "",
    end_date: "",
    revenue: null,
};
export default {
    props: {
        showModal: {
            type: Boolean,
            required: true,
        },
        previousPeriodId: {
            type: Number,
            required: false,
            default: -1,
        },
        startDate: {
            type: [Date, String],
            required: false,
            default: () => new Date().toISOString().split("T")[0],
        },
        editPeriod: {
            type: Object,
            required: false,
            default: () => null,
        },
    },
    data() {
        return {
            modal: this.showModal,
            period: structuredClone(periodForm),
            duplicateFromPreviousYear: true,
            errorMessage: "",
            duplicate: {
                descriptions: true,
                values: false,
                notes: true,
            },
            interval: "yearly",
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.errorMessage = "";
            if (!this.editPeriod) {
                this.period.start_date = this.startDate;
                this.createFormattedEndDate();
            } else this.interval = "";
        },
        save() {
            if (this.period.id) this.update();
            else this.create();
        },
        async create() {
            if (this.duplicateFromPreviousYear) {
                this.period.duplicateFromPeriodId = this.previousPeriodId;
                this.period.duplicate = {
                    descriptions: this.duplicate.descriptions,
                    values: this.duplicate.values,
                    notes: this.duplicate.notes,
                };
            }

            try {
                await periodApi.add(this.period);
                this.$emit("close");
                this.$emit("saved");
            } catch (error) {
                console.log(error);
                this.errorMessage = error.response.data.message;
            }
        },
        async update() {
            this.period.start_date = this.formatDate(this.period.start_date);
            try {
                await periodApi.edit(this.period.id, { ...this.period });
                this.$emit("close");
                this.$emit("saved");
            } catch (error) {
                console.log(error);
                this.errorMessage = error.response.data.message;
            }
        },
        createFormattedEndDate() {
            const startDate = new Date(this.period.start_date);
            const addMonths = this.interval === "yearly" ? 12 : 3;
            if (!isNaN(startDate.getTime())) {
                let endDate = new Date(startDate);
                endDate.setMonth(startDate.getMonth() + addMonths);
                endDate.setDate(endDate.getDate() - 1);
                this.period.end_date = this.formatDate(endDate);
            }
        },
        formatDate(date) {
            return new Date(date).toISOString().split("T")[0];
        },
    },
    watch: {
        showModal: {
            handler(val) {
                this.modal = val;
                if (val) this.init();
            },
        },
        editPeriod: {
            handler(val) {
                if (val) {
                    this.period = val;
                } else {
                    this.period = structuredClone(periodForm);
                }
            },
            deep: true,
        },
        interval: {
            handler(val) {
                if (val === "custom") return;
                this.createFormattedEndDate();
            },
            immediate: true,
        },
        "period.start_date": {
            handler() {
                if (this.interval === "custom") return;
                this.createFormattedEndDate();
            },
        },
    },
};
</script>
