<template>
    <!-- Navigation buttons -->
    <div class="custom-metric-sidebar__navigation" @keydown="handleKeydown">
        <span class="cursor-pointer no-select" @click="handleClick(-1)">
            <i class="fa-light fa-chevron-left mr-3"></i>
            {{ $t("previous") }}
        </span>
        <span class="cursor-pointer no-select" @click="handleClick(1)">
            {{ $t("next") }}
            <i class="fa-light fa-chevron-right ml-3"></i>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        selectedCustomMetricValue: {
            type: Object,
            required: true,
        },
    },
    mounted() {
        document.addEventListener("keydown", this.handleKeydown);
    },
    methods: {
        handleClick(direction) {
            // Emit a navigation event with the direction
            this.$emit("navigate", {
                direction,
                metric_id: this.selectedCustomMetricValue.metric_id,
                localId: this.selectedCustomMetricValue.localId,
            });
        },
        handleKeydown(event) {
            const valueInput = document.getElementById("metric_value_input");

            // Check if an input field or the description field is focused
            if (
                document.activeElement.tagName === "INPUT" ||
                document.activeElement.className === "ql-editor"
            ) {
                return;
            }

            // Handle navigation or focus based on the pressed key
            if (event.key === "ArrowRight") {
                this.handleClick(1);
            } else if (event.key === "ArrowLeft") {
                this.handleClick(-1);
            } else if (event.key === "Enter") {
                if (valueInput) {
                    // Focus the 'metric_value' input field on 'Enter' key press
                    valueInput.focus();
                }
            }
        },
    },
    destroyed() {
        document.removeEventListener("keydown", this.handleKeydown);
    },
};
</script>
