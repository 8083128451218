<template>
    <b-row class="clickable cc-accordion-item-list" @click="onClickMore(item)">
        <b-col cols="6">
            <i class="fas fa-map-marker-alt text-gray-500"></i>
            <span class="badge badge-pill badge--blue ml-4">
                <span>{{ item.name }}</span>
            </span>
        </b-col>
        <b-col class="text-right">
            <b-button
                :id="'action_button' + item.id"
                size="sm"
                variant="link"
                @click="onClickMore(item)"
            >
                <slot></slot>
                <i class="fas fa-ellipsis-v"></i>
            </b-button>
            <b-tooltip :target="'action_button' + item.id" triggers="hover">
                {{ $t("more_actions") }}
            </b-tooltip>
        </b-col>
    </b-row>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
            description: "",
        },
    },
    methods: {
        onClickMore() {
            this.$emit("more", this.item);
        },
    },
};
</script>
