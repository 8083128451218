<template>
    <div>
        <!-- TITLE -->
        <label for="radio-group" class="mb-2">
            {{ $t("label_domain") }}
            <span class="text-danger ml-2">*</span>
        </label>

        <p v-if="customMetric.id" class="text-mini color-error">
            {{ $t("metric_domain_edit_warning") }}
        </p>

        <!-- DOMAIN SELECT RADIO BOXES -->
        <label
            v-for="item in domainOptions"
            :key="item.key"
            class="custom-metric__radio-select"
            :class="
                form.domain === item.key
                    ? 'custom-metric__radio-select--selected'
                    : ''
            "
            :for="item.key"
        >
            <div class="flex flex-column">
                <div class="text-gray-700 mb-2">
                    {{ item.header }}
                </div>
                <span class="text-sm text-gray-500" :id="item.header">
                    {{ item.text }}
                </span>
            </div>
            <RadioButton
                v-model="form.domain"
                :inputId="item.key"
                name="some-radios"
                :value="item.key"
            ></RadioButton>
        </label>

        <div>
            <!-- SITES SELECT -->
            <Transition name="fade" mode="out-in">
                <div
                    v-if="form.domain === 'site' || form.domain === 'combined'"
                    class="mb-4"
                >
                    <label for="metric-sites">
                        {{ $t("label_sites") }}
                        <span class="ml-2">*</span>
                    </label>
                    <multiSelect
                        v-model="siteSelection"
                        :options="siteOptions"
                        label="text"
                        track-by="value"
                        :close-on-select="false"
                        :preserve-search="true"
                        :showLabels="false"
                        searchable
                        multiple
                        :placeholder="$t('site_selection_required')"
                        :allowEmpty="false"
                    ></multiSelect>
                    <div :state="siteState" v-if="validate">
                        {{ $t("site_selection_required") }}
                    </div>
                </div>
            </Transition>

            <!-- BU SELECT -->
            <Transition name="fade" mode="out-in">
                <div
                    v-if="form.domain === 'bu' || form.domain === 'combined'"
                    class="mb-4"
                >
                    <label for="metric-sites">
                        {{ $t("label_business_units") }}
                        <span class="ml-2">*</span>
                    </label>
                    <multiSelect
                        v-model="buSelection"
                        :options="buOptions"
                        label="text"
                        track-by="value"
                        :close-on-select="false"
                        :preserve-search="true"
                        :showLabels="false"
                        searchable
                        multiple
                        :placeholder="$t('bu_selection_required')"
                        :allowEmpty="false"
                    ></multiSelect>
                    <div :state="buState" v-if="validate">
                        {{ $t("bu_selection_required") }}
                    </div>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import RadioButton from "primevue/radiobutton";

export default {
    name: "MetricDomainSelector",
    components: {
        RadioButton,
    },
    props: {
        customMetric: {
            type: Object,
            required: true,
        },
        validate: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            form: {
                domain: "whole_company",
                siteIds: [],
                businessUnitIds: [],
            },
            domainOptions: [
                {
                    key: "whole_company",
                    header: this.$t("metric_whole_company"),
                    text: this.$t("metric_whole_company_text"),
                },
                {
                    key: "site",
                    header: this.$t("metric_site_specific"),
                    text: this.$t("metric_site_specific_text"),
                },
                {
                    key: "bu",
                    header: this.$t("metric_bu_specific"),
                    text: this.$t("metric_bu_specific_text"),
                },
                {
                    key: "combined",
                    header: this.$t("metric_combined"),
                    text: this.$t("metric_combined_text"),
                },
            ],
            preventUpdate: false,
        };
    },
    computed: {
        ...mapState({
            businessUnits: (state) => state.data.businessUnits,
            sites: (state) => state.data.sites,
        }),
        siteOptions() {
            const sites = this.sites.map((x) => {
                return { value: x.id, text: x.name };
            });
            return sites;
        },
        buOptions() {
            const bu = this.businessUnits.map((x) => {
                return { value: x.id, text: x.name };
            });
            return bu;
        },
        domainState() {
            return this.form.domain?.length >= 1;
        },
        siteState() {
            if (this.customMetric.global_site) return true;
            return this.form.siteIds.length >= 1;
        },
        buState() {
            if (this.customMetric.global_business_unit) return true;
            return this.form.businessUnitIds.length >= 1;
        },
        siteSelection: {
            get() {
                return this.sites
                    .filter((x) => this.form.siteIds?.includes(x.id))
                    .map((x) => {
                        return { value: x.id, text: x.name };
                    });
            },
            set(value) {
                this.form.siteIds = value.map((x) => x.value);
            },
        },
        buSelection: {
            get() {
                return this.businessUnits
                    .filter((x) => this.form.businessUnitIds?.includes(x.id))
                    .map((x) => {
                        return { value: x.id, text: x.name };
                    });
            },
            set(value) {
                this.form.businessUnitIds = value.map((x) => x.value);
            },
        },
    },
    methods: {
        resetData() {
            this.form = {
                domain: null,
                siteIds: [],
                businessUnitIds: [],
            };
        },
        generateData() {
            const global_site =
                this.form.domain !== "site" && this.form.domain !== "combined";
            const global_business_unit =
                this.form.domain !== "bu" && this.form.domain !== "combined";

            const update = {
                global_site,
                global_business_unit,
                siteIds: global_site ? [] : this.form.siteIds,
                businessUnitIds: global_business_unit
                    ? []
                    : this.form.businessUnitIds,
            };

            this.$emit("input:metric", update);
        },
        setFormData() {
            const domain =
                this.customMetric.global_site &&
                this.customMetric.global_business_unit
                    ? "whole_company"
                    : !this.customMetric.global_site &&
                      this.customMetric.global_business_unit
                    ? "site"
                    : this.customMetric.global_site &&
                      !this.customMetric.global_business_unit
                    ? "bu"
                    : "combined";

            this.form = {
                domain,
                siteIds: this.customMetric.siteIds,
                businessUnitIds: this.customMetric.businessUnitIds,
            };
        },
    },
    watch: {
        form: {
            handler() {
                if (this.preventUpdate) return;
                this.generateData();
            },
            deep: true,
        },
        customMetric: {
            handler() {
                this.preventUpdate = true;
                this.setFormData();
                this.$nextTick(() => {
                    this.preventUpdate = false;
                });
            },
            immediate: true,
        },
    },
};
</script>
