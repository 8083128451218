<template>
    <div>
        <b-card class="cc-content cc-content-card">
            <b-row>
                <b-col>
                    <h1>{{ $t("company_button_data_configuration") }}</h1>
                </b-col>
            </b-row>
            <b-row class="mt-5">
                <b-col cols="4">
                    {{ $t("input_value_threshold") }}
                    <p class="text-xs text-gray-500 mt-2">
                        {{ $t("input_value_threshold_description") }}
                    </p>
                </b-col>
                <b-col>
                    <CompanyValueTreshold
                        :value-threshold-object="valueThreshold"
                        @update:valueTreshold="
                            updateCompanyConfig(
                                'input_value_threshold',
                                +$event
                            )
                        "
                    />
                </b-col>
            </b-row>
            <b-row class="mt-5">
                <b-col cols="4">
                    {{ $t("consolidation_approach") }}
                    <p class="text-xs text-gray-500 mt-2">
                        {{ $t("consolidation_approach_description") }}
                    </p>
                </b-col>
                <b-col>
                    <CompanyConsolidationApproach
                        :consolidation-approach-object="consolidationApproach"
                        @update:consolidationApproach="
                            updateCompanyConfig('data_consolidation', $event)
                        "
                    />
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import CompanyConsolidationApproach from "./CompanyConsolidationApproach.vue";
import CompanyValueTreshold from "./CompanyValueTreshold.vue";
import * as types from "@/store/types";

import companyConfigApi from "@/api/company-config";

export default {
    components: { CompanyConsolidationApproach, CompanyValueTreshold },
    data() {
        return {
            config: [],
        };
    },
    computed: {
        valueThreshold() {
            return this.config.find(
                (item) => item.configuration_key === "input_value_threshold"
            );
        },
        consolidationApproach() {
            return this.config.find(
                (item) => item.configuration_key === "data_consolidation"
            );
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.getCompanyConfig();
        },
        async getCompanyConfig() {
            try {
                const response = await companyConfigApi.getCompanyConfig();
                this.config = response.data.data;
            } catch (error) {
                console.log(error);
            }
        },
        async updateCompanyConfig(key, value) {
            const configItem = this.config.find(
                (item) => item.configuration_key === key
            );

            if (configItem) configItem.configuration_value = value;

            try {
                await companyConfigApi.updateCompanyConfigItem(
                    configItem?.id ?? undefined,
                    key,
                    value
                );
                this.getCompanyConfig();
                this.$store.dispatch(
                    types.SET_TOAST,
                    this.$t("config_updated")
                );
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
