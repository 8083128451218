<template>
    <div class="cc-accordion">
        <button
            class="cc-accordion__header shadow medium"
            @click.prevent="onClickAccordionHeader"
        >
            <b-row>
                <b-col>
                    <div
                        class="cc-accordion__header-icon"
                        v-if="type == 'dynamic'"
                    >
                        <i
                            v-if="!showAccordionItems"
                            class="fas fa-chevron-right"
                        ></i>
                        <i
                            v-if="showAccordionItems"
                            class="fas fa-chevron-down"
                        ></i>
                    </div>
                    <div
                        class="cc-accordion__header-icon"
                        v-if="type == 'static'"
                    >
                        <i class="fas fa-square text-muted"></i>
                    </div>
                    <span class="cc-accordion__header-text">{{
                        item.name
                    }}</span>
                </b-col>

                <b-col class="text-right">
                    <b-button
                        :id="'action_button' + item.id"
                        size="sm"
                        variant="link"
                        @click.prevent="onClickMore(item)"
                    >
                        <i class="fas fa-ellipsis-v"></i>
                    </b-button>
                    <b-tooltip
                        :target="'action_button' + item.id"
                        triggers="hover"
                    >
                        {{ $t("more_actions") }}
                    </b-tooltip>
                </b-col>
            </b-row>
        </button>

        <transition name="slide-down">
            <div class="cc-accordion__list" v-if="showAccordionItems">
                <div class="item-list cc-data-list">
                    <slot></slot>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "CCDefaultAccordion",
    props: {
        item: {
            type: Object,
            required: true,
            description: "",
        },
        type: {
            type: String,
            required: false,
            default: "dynamic",
            description:
                "In some cases we use the layout of the accordion to list items. So the static type is available for these cases.",
        },
    },
    data() {
        return {
            showAccordionItems: false,
        };
    },
    methods: {
        onClickAccordionHeader() {
            if (this.type == "static") {
                this.$emit("more", this.item);
                return;
            }

            this.showAccordionItems = !this.showAccordionItems;
        },
        onClickMore() {
            this.$emit("more", this.item);
        },
    },
};
</script>
