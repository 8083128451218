<template>
    <div>
        <b-row>
            <b-col>
                <h1>{{ $t("companyBusinessUnits_header") }}</h1>
            </b-col>
            <b-col class="text-right" v-if="$can('create', 'business_unit')">
                <b-button variant="primary" @click="showBusinessUnitModal()">{{
                    $t("companyBusinessUnits_button_new_unit")
                }}</b-button>
            </b-col>
        </b-row>
        <b-row class="mt-5">
            <b-col>
                <div
                    class="flex justify-content-between align-items-center mb-2"
                    v-for="unit in units"
                    :key="unit.id"
                >
                    <span>
                        <i class="fas fa-building text-gray-500"></i>
                        <span
                            class="badge badge-pill ml-4"
                            :style="getBadgeStyle(unit.colour)"
                        >
                            <span>{{ unit.name }}</span>
                        </span>
                    </span>

                    <b-dropdown
                        no-caret
                        right
                        variant="link"
                        v-if="
                            $can('update', 'business_unit') ||
                            $can('delete', 'business_unit')
                        "
                    >
                        <template #button-content>
                            <i
                                class="far fa-ellipsis-v"
                                :id="'action_button' + unit.id"
                            ></i>
                        </template>
                        <b-tooltip
                            :target="'action_button' + unit.id"
                            triggers="hover"
                        >
                            {{ $t("more_actions") }}
                        </b-tooltip>
                        <b-dropdown-item
                            href="#"
                            @click="showBusinessUnitModal(unit)"
                            v-if="$can('update', 'business_unit')"
                        >
                            {{ $t("companyBusinessUnits_edit") }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            href="#"
                            @click="confirmDeleteBU(unit)"
                            v-if="$can('delete', 'business_unit')"
                        >
                            {{ $t("companyBusinessUnits_delete") }}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </b-col>
        </b-row>

        <!-- CONFIRM DELETE MODAL -->
        <b-modal
            id="modal-delete"
            :title="`${$t('delete')} ${selectedUnit.name}?`"
            v-model="confirmDelete"
            ok-variant="danger"
            :ok-title="$t('delete')"
            @ok="deleteBusinessUnit"
        >
            <p class="my-4">
                {{ $t("companyBusinessUnits_delete_text") }}
            </p>
        </b-modal>

        <!-- BU MODAL -->
        <company-business-units-modal
            :showModal="showFormModal"
            v-model="selectedUnit"
            @save="saveBusinessUnit"
            @close="showFormModal = false"
            :title="
                selectedUnit.id
                    ? $t('companyBusinessUnits_edit')
                    : $t('companyBusinessUnits_create')
            "
        ></company-business-units-modal>
    </div>
</template>

<script>
import buApi from "@/api/business_unit";
import CompanyBusinessUnitsModal from "@/components/company/CompanyBusinessUnitsModal";
import * as types from "@/store/types";

export default {
    components: {
        CompanyBusinessUnitsModal,
    },
    data() {
        return {
            units: [],
            confirmDelete: false,
            selectedUnit: {},
            showFormModal: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.getBusinessUnits();
        },
        showBusinessUnitModal(unit = { colour: "", name: "" }) {
            this.selectedUnit = unit;
            this.showFormModal = true;
        },
        async getBusinessUnits() {
            try {
                const units = await buApi.list();
                this.units = units.data;
                this.$store.dispatch(types.SET_DATA, {
                    name: "businessUnits",
                    data: this.units,
                });
            } catch (error) {
                console.log(error);
            }
        },
        async deleteBusinessUnit() {
            try {
                await buApi.delete(this.selectedUnit.id);
                this.getBusinessUnits();
                this.selectedUnit = {};
                this.confirmDelete = false;
            } catch (error) {
                console.log(error);
            }
        },
        async saveBusinessUnit() {
            if (!this.selectedUnit.id) {
                try {
                    await buApi.add(
                        this.selectedUnit.name,
                        this.selectedUnit.colour
                    );
                    this.showFormModal = false;
                    this.selectedUnit = {};
                } catch (error) {
                    console.log(error);
                }
            } else {
                try {
                    await buApi.update(
                        this.selectedUnit.id,
                        this.selectedUnit.name,
                        this.selectedUnit.colour
                    );
                    this.showFormModal = false;
                    this.selectedUnit = {};
                } catch (error) {
                    console.log(error);
                }
            }

            this.getBusinessUnits();
        },
        confirmDeleteBU(unit) {
            this.selectedUnit = unit;
            this.confirmDelete = true;
        },
        getBadgeStyle(colour) {
            if (colour) {
                return `color: ${colour}; background-color: ${colour}10; box-shadow: none;`;
            }

            return "";
        },
    },
};
</script>
