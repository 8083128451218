<template>
    <b-modal
        id="modal-invite-form"
        :title="$t('team_invite_form_title')"
        hide-footer
        @hidden="$emit('close')"
        v-model="modal"
        size="lg"
    >
        <b-form @submit.prevent="save">
            <b-row>
                <b-col>
                    <label>{{ $t("email") }}</label>
                    <b-form-input
                        type="email"
                        required
                        v-model="invite.email"
                        :readonly="!!editInvite.id"
                    />
                </b-col>
            </b-row>

            <b-row class="mt-4">
                <b-col>
                    <label>{{ $t("first_name") }}</label>
                    <b-form-input
                        required
                        v-model="invite.first_name"
                        :readonly="!!editInvite.id"
                    />
                </b-col>
                <b-col>
                    <label>{{ $t("last_name") }} </label>
                    <b-form-input
                        required
                        v-model="invite.last_name"
                        :readonly="!!editInvite.id"
                    />
                </b-col>
            </b-row>

            <b-row class="mt-4">
                <b-col>
                    <label>{{ $t("team_invite_form_role") }}</label>
                    <b-form-select
                        v-model="invite.role"
                        :options="roles"
                        required
                    ></b-form-select>
                    <p class="text-tiny text-gray-500">
                        {{ $t("team_invite_form_permissions_text") }}
                    </p>
                    <p class="m-0 text-tiny text-gray-500">
                        <span class="text-gray-600">
                            {{ $t("form_admin") }} </span
                        >: {{ $t("form_admin_text") }}
                    </p>
                    <p class="m-0 text-tiny text-gray-500">
                        <span class="text-gray-600">
                            {{ $t("form_editor") }} </span
                        >: {{ $t("form_editor_text") }}
                    </p>
                    <p class="m-0 text-tiny text-gray-500">
                        <span class="text-gray-600">
                            {{ $t("form_contributor") }} </span
                        >: {{ $t("form_contributor_text") }}
                    </p>
                    <p class="m-0 text-tiny text-gray-500">
                        <span class="text-gray-600">
                            {{ $t("form_auditor") }} </span
                        >: {{ $t("form_auditor_text") }}
                    </p>
                    <p class="m-0 text-tiny text-gray-500">
                        <span class="text-gray-600">
                            {{ $t("form_read_only") }} </span
                        >: {{ $t("form_read_only_text") }}
                    </p>
                </b-col>
            </b-row>

            <sites-multiselect
                :userSelected="invite.role_info.sites"
                :userAll="invite.role_info.access_all_sites"
                @update="handleSitesUpdate"
            />

            <business-units-multiselect
                :userSelected="invite.role_info.business_units"
                :userAll="invite.role_info.access_all_business_units"
                @update="handleBusinessUnitsUpdate"
            />
            <tags-multiselect
                :userSelected="invite.role_info.tag_ids"
                :userAll="invite.role_info.access_all_tags"
                @update="handleTagsUpdate"
            />

            <b-row class="mt-4">
                <b-col>
                    <label>{{ $t("team_invite_form_custom_message") }}</label>
                    <b-form-textarea
                        v-model="custom_message"
                        :readonly="!!editInvite.id"
                    />
                    <p class="text-tiny text-gray-500">
                        {{ $t("team_invite_form_custom_message_text") }}
                    </p>
                </b-col>
            </b-row>

            <b-row class="mt-4">
                <b-col align="end" class="mb-4">
                    <b-button
                        key="close"
                        type="outline-primary"
                        @click.prevent="$emit('close')"
                        class="mr-2"
                    >
                        <span class="btn-inner--text">{{ $t("cancel") }}</span>
                    </b-button>
                    <b-button type="submit" variant="primary">
                        {{ $t("save") }}
                    </b-button>
                    <p v-if="error" class="mt-2 text-mini color-error">
                        {{ error }}
                    </p>
                </b-col>
            </b-row>
        </b-form>
    </b-modal>
</template>

<script>
import inviteApi from "@/api/invite";
import BusinessUnitsMultiselect from "./components/BusinessUnitsMultiselect.vue";
import SitesMultiselect from "./components/SitesMultiselect.vue";
import TagsMultiselect from "./components/TagsMultiselect.vue";

export default {
    components: { SitesMultiselect, BusinessUnitsMultiselect, TagsMultiselect },
    props: {
        editInvite: {
            type: Object,
            required: false,
            default: () => {},
        },
        showModal: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            sites: this.$store.getters.getSites(),
            modal: this.showModal,
            invite: { role_info: { access_all_sites: true } },
            custom_message: "",
            showCompanySearch: false,
            roles: [
                { value: "admin", text: this.$t("form_admin") },
                { value: "editor", text: this.$t("form_editor") },
                { value: "contributor", text: this.$t("form_contributor") },
                { value: "auditor", text: this.$t("form_auditor") },
                { value: "read-only", text: this.$t("form_read_only") },
            ],
            error: "",
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.error = "";
            this.invite = {
                role: "contributor",
                ...{
                    role_info: {
                        access_all_sites: true,
                        sites: [],
                        access_all_business_units: true,
                        business_units: [],
                        access_all_tags: true,
                        tag_ids: [],
                    },
                },
                ...this.editInvite,
            };
        },
        handleSitesUpdate({ selected, all }) {
            this.updateRoleInfo("sites", "access_all_sites", selected, all);
        },
        handleBusinessUnitsUpdate({ selected, all }) {
            this.updateRoleInfo(
                "business_units",
                "access_all_business_units",
                selected,
                all
            );
        },
        handleTagsUpdate({ selected, all }) {
            this.updateRoleInfo("tag_ids", "access_all_tags", selected, all);
        },
        updateRoleInfo(selectedKey, allKey, selected, all) {
            this.invite.role_info[selectedKey] = selected;
            this.invite.role_info[allKey] = all;
        },

        async save() {
            this.error = "";

            if (this.invite.role_info.access_all_sites)
                this.invite.role_info.sites = [];

            if (this.invite.role_info.access_all_business_units)
                this.invite.role_info.business_units = [];

            if (this.invite.role_info.access_all_tags)
                this.invite.role_info.tag_ids = [];

            if (this.editInvite?.invite_id) {
                try {
                    await inviteApi.updateInvite(
                        this.editInvite.invite_id,
                        this.invite
                    );
                    this.$emit("saved");
                } catch (error) {
                    console.log(error);
                    this.error = error.response.data;
                }
            } else {
                try {
                    await inviteApi.createInvite(
                        this.invite,
                        this.custom_message
                    );
                    this.$emit("saved");
                } catch (error) {
                    console.log(error);
                    this.error = error.response.data;
                }
            }
        },
    },
    watch: {
        showModal: {
            handler(val) {
                this.modal = val;

                if (val) this.init();
            },
        },
        modal: {
            handler(val) {
                if (val === false) this.$emit("close");
            },
        },
    },
};
</script>
