<template>
    <b-card class="cc-content cc-content-card">
        <b-row>
            <b-col>
                <h1>{{ $t("emissionCategory_header") }}</h1>
            </b-col>
        </b-row>
        <b-row>
            <!-- UPSTREAM -->
            <b-col sm="12" md="4" class="mt-5">
                <div class="bold">
                    <i
                        class="fas fa-rotate-left mr-2"
                        style="transform: rotate(135deg)"
                    ></i>
                    {{ $t("emissionCategory_upstream") }}
                </div>

                <div class="mt-4 text-tiny text-uppercase bold">
                    {{ $t("emissionCategory_scope_2") }}
                </div>
                <div
                    v-for="c in filterCategories('upstream', 2)"
                    :key="c.id"
                    class="mt-2"
                >
                    <b-form-checkbox
                        :checked="checkedCategories.includes(c.id)"
                        switch
                        @change="update(c.id)"
                        :disabled="!$can('update', 'emission_category_config')"
                    >
                        {{ $t(c.name) }}
                    </b-form-checkbox>
                </div>
                <div class="mt-4 text-tiny text-uppercase bold">
                    {{ $t("emissionCategory_scope_3") }}
                </div>
                <div
                    v-for="c in filterCategories('upstream', 3)"
                    :key="c.id"
                    class="mt-2"
                >
                    <b-form-checkbox
                        :checked="checkedCategories.includes(c.id)"
                        switch
                        @change="update(c.id)"
                        :disabled="!$can('update', 'emission_category_config')"
                    >
                        {{ $t(c.name) }}
                    </b-form-checkbox>
                </div>
            </b-col>

            <!-- DIRECT -->
            <b-col sm="12" md="4" class="mt-5">
                <div class="bold">
                    <i class="fas fa-long-arrow-alt-up mr-2"></i>
                    {{ $t("emissionCategory_reporting") }}
                </div>

                <div class="mt-4 text-tiny text-uppercase bold">
                    {{ $t("emissionCategory_scope_1") }}
                </div>
                <div
                    v-for="c in filterCategories('direct')"
                    :key="c.id"
                    class="mt-2"
                >
                    <b-form-checkbox
                        :checked="checkedCategories.includes(c.id)"
                        switch
                        @change="update(c.id)"
                        :disabled="!$can('update', 'emission_category_config')"
                    >
                        {{ $t(c.name) }}
                    </b-form-checkbox>
                </div>
            </b-col>

            <!-- DOWNSTREAM -->
            <b-col sm="12" md="4" class="mt-5">
                <div class="bold">
                    <i
                        class="fas fa-rotate-left mr-2"
                        style="transform: rotate(-45deg)"
                    ></i>
                    {{ $t("emissionCategory_downstream") }}
                </div>

                <div class="mt-4 text-tiny text-uppercase bold">
                    {{ $t("emissionCategory_scope_3") }}
                </div>
                <div
                    v-for="c in filterCategories('downstream')"
                    :key="c.id"
                    class="mt-2"
                >
                    <b-form-checkbox
                        :checked="checkedCategories.includes(c.id)"
                        switch
                        @change="update(c.id)"
                        :disabled="!$can('update', 'emission_category_config')"
                    >
                        {{ $t(c.name) }}
                    </b-form-checkbox>
                </div>
            </b-col>
        </b-row>
        <b-row style="margin-top: 40px">
            <b-img src="/GHGProtocol.png" class="w-full" />
        </b-row>
    </b-card>
</template>

<script>
import categoryApi from "@/api/emission_categories";
import configApi from "@/api/emission_category_config.js";

export default {
    data() {
        return {
            categories: [],
            checkedCategories: [],
            config: {},
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.getCategories();
            await this.getCurrentConfig();
        },
        async getCategories() {
            try {
                const result = await categoryApi.listAllCategories();
                this.categories = result.data;
            } catch (error) {
                console.log(error);
            }
        },
        async getCurrentConfig() {
            try {
                const res = await configApi.get();
                this.checkedCategories = res.data;
            } catch (error) {
                console.log(error);
            }
        },
        async setCurrentConfig() {
            try {
                await configApi.update(this.checkedCategories);
            } catch (error) {
                console.log(error);
            }
        },
        filterCategories(valuechain, scope) {
            const valueChainFilter = this.categories.filter(
                (x) => x.value_chain === valuechain
            );
            if (scope)
                return valueChainFilter.filter((x) => +x.scope === scope);
            return valueChainFilter;
        },
        update(id) {
            const index = this.checkedCategories.findIndex((x) => x === id);

            if (index === -1) this.checkedCategories.push(id);
            else this.checkedCategories.splice(index, 1);

            this.setCurrentConfig();
        },
    },
};
</script>
