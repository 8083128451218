<template>
    <b-modal
        id="modal-add-sitegroup"
        :title="title"
        :ok-title="$t('save')"
        @ok="save"
        size="lg"
    >
        <div class="row">
            <div class="col">
                <label for="input-new-sitegroup-name">
                    {{ $t("label_name") }}
                </label>
                <b-input id="input-new-sitegroup-name" v-model="name"></b-input>
            </div>
        </div>

        <br />

        <div class="row">
            <div class="col">
                <b>{{ $t("contact") }}</b>
                <fieldset>
                    <label for="input-new-sitegroup-contact-name">
                        {{ $t("label_name") }}
                    </label>
                    <b-input
                        disabled
                        id="input-new-sitegroup-contact-name"
                        v-model="contact.name"
                    ></b-input>
                </fieldset>
                <fieldset>
                    <label for="input-new-sitegroup-contact-lastname">
                        {{ $t("last_name") }}
                    </label>
                    <b-input
                        disabled
                        id="input-new-sitegroup-contact-lastname"
                        v-model="contact.lastName"
                    ></b-input>
                </fieldset>
                <fieldset>
                    <label for="input-new-sitegroup-contact-email">
                        {{ $t("email") }}
                    </label>
                    <b-input
                        disabled
                        id="input-new-sitegroup-contact-email"
                        v-model="contact.email"
                    ></b-input>
                </fieldset>
                <fieldset>
                    <b-checkbox
                        disabled
                        id="input-new-sitegroup-contact-save"
                        v-model="contact.save"
                    ></b-checkbox>
                    <label for="input-new-sitegroup-contact-save">
                        {{ $t("label_save_as_contact") }}
                    </label>
                </fieldset>
            </div>
        </div>
        <b-button v-if="!isNew" variant="danger" @click="deleteSiteGroup">
            {{ $t("label_delete_group") }}
        </b-button>
    </b-modal>
</template>

<script>
import siteGroupApi from "@/api/site_group.js";

export default {
    props: ["siteGroup"],
    data: () => ({
        name: "",
        contact: {
            name: "",
            lastName: "",
            email: "",
            save: false,
        },
    }),

    watch: {
        siteGroup() {
            this.setData();
        },
    },

    methods: {
        save() {
            let params = {
                name: this.name,
                contact: this.contact,
            };
            if (this.isNew) {
                siteGroupApi.addSiteGroup(params).then(() => {
                    this.reset();
                    this.$emit("update");
                });
            } else {
                let id = this.siteGroup.id;
                siteGroupApi.updateSiteGroup(id, params).then(() => {
                    this.reset();
                    this.$emit("update");
                });
            }
        },

        reset() {
            this.name = "";
            this.contact = {
                name: "",
                lastName: "",
                email: "",
                save: false,
            };
        },

        setData() {
            if (this.isNew) {
                this.reset();
            } else {
                this.name = this.siteGroup.name;
            }
        },

        deleteSiteGroup() {
            siteGroupApi.deleteSiteGroup(this.siteGroup.id).then(() => {
                this.reset();
                this.$emit("update");
                this.$bvModal.hide("modal-add-sitegroup");
            });
        },
    },

    computed: {
        isNew() {
            return !this.siteGroup || this.siteGroup.id <= 0;
        },
        title() {
            return this.isNew ? this.$t("new_group") : this.$t("edit_group");
        },
    },
};
</script>
