<template>
    <div>
        <b-card
            class="cc-content cc-content-card"
            v-if="$can('read', 'company')"
        >
            <b-row>
                <b-col>
                    <h1>{{ $t("company_info") }}</h1>
                </b-col>
                <b-col
                    class="text-right flex align-items-center justify-content-end"
                >
                    <saving-status-loader
                        class="mr-4"
                        :savedStatus="savingStatus"
                        :saving="saving"
                    />
                    <transition name="fade" mode="out-in">
                        <base-button
                            key="edit"
                            v-if="!edit && $can('update', 'company')"
                            icon
                            type="primary"
                            @click="handleEdit"
                        >
                            <span class="btn-inner--icon"
                                ><i class="far fa-pencil-alt"></i
                            ></span>
                            <span class="btn-inner--text">
                                {{ $t("edit") }}
                            </span>
                        </base-button>
                        <base-button
                            key="save"
                            v-else-if="edit"
                            icon
                            type="success"
                            @click="handleSave"
                        >
                            <span class="btn-inner--icon"
                                ><i class="far fa-save"></i
                            ></span>
                            <span class="btn-inner--text">
                                {{ $t("save") }}
                            </span>
                        </base-button>
                    </transition>
                </b-col>
            </b-row>
            <b-row align-v="center" class="mt-5">
                <b-col cols="2">
                    <label for="company-name">{{ $t("label_name") }}</label>
                </b-col>
                <b-col>
                    <div v-if="!edit" id="company-name">{{ company.name }}</div>
                    <b-input
                        v-else
                        :placeholder="$t('label_name')"
                        v-model="editingCompany.name"
                        id="company-name-edit"
                    ></b-input>
                </b-col>
            </b-row>
            <b-row align-v="center" class="mt-5">
                <b-col cols="2">
                    <label for="company-sector">
                        {{ $t("label_sector") }}
                    </label>
                </b-col>
                <b-col>
                    <div v-if="!edit" id="company-sector">
                        {{ company.sector }}
                    </div>
                    <b-input
                        v-else
                        :placeholder="$t('label_sector')"
                        v-model="editingCompany.sector"
                        id="company-sector-edit"
                    ></b-input>
                </b-col>
            </b-row>
            <b-row align-v="center" class="mt-5">
                <b-col cols="2">
                    <label for="company-adress1">
                        {{ $t("label_address") }}
                    </label>
                </b-col>
                <b-col id="company-address1">
                    <div>
                        <template v-if="!edit">{{
                            company.address_line_1
                        }}</template>
                        <template v-else>
                            <b-input
                                class="mb-1"
                                :placeholder="$t('label_addres_line_1')"
                                v-model="editingCompany.address_line_1"
                                id="company-address-line-1-edit"
                            ></b-input>
                        </template>
                    </div>
                    <div>
                        <template v-if="!edit && company.address_line_2">{{
                            company.address_line_2
                        }}</template>
                        <template v-else-if="edit">
                            <b-input
                                class="mb-1"
                                :placeholder="$t('label_addres_line_2')"
                                v-model="editingCompany.address_line_2"
                                id="company-address-line-2-edit"
                            ></b-input>
                        </template>
                    </div>
                    <div>
                        <template v-if="!edit">{{
                            company.postcode + " " + company.city
                        }}</template>
                        <template v-else>
                            <b-row>
                                <b-col cols="6">
                                    <b-input
                                        class="mb-1 d-inline-block"
                                        :placeholder="$t('label_zip')"
                                        v-model="editingCompany.postcode"
                                        id="company-postcode-edit"
                                    ></b-input>
                                </b-col>
                                <b-col cols="6">
                                    <b-input
                                        class="mb-1 d-inline-block"
                                        :placeholder="$t('label_city')"
                                        v-model="editingCompany.city"
                                        id="company-city-edit"
                                    ></b-input>
                                </b-col>
                            </b-row>
                        </template>
                    </div>
                    <div>
                        <template v-if="!edit">{{
                            countryMapper[company.country_code]
                        }}</template>
                        <template v-else-if="edit">
                            <b-select
                                class="mb-1"
                                id="select-company-address-country"
                                v-model="editingCompany.country_code"
                                :options="countrySelectOptions"
                            ></b-select>
                        </template>
                    </div>
                </b-col>
            </b-row>

            <b-row
                align-v="center"
                class="mt-5"
                v-if="$can('read', 'company_photo')"
            >
                <b-col cols="2">
                    <label for="company-logo">{{ $t("label_logo") }}</label>
                </b-col>
                <b-col>
                    <template v-if="!edit">
                        <div class="flex">
                            <!-- <img v-if="company.logo" id="company-logo" :src="company.logo" style="padding: 10px; width: 200px" /> -->
                            <b-avatar
                                variant="primary"
                                :text="companyInitials"
                                :src="companyLogo"
                                class="mr-3 cc-badge-yellow"
                            ></b-avatar>
                        </div>
                    </template>
                    <template v-else-if="edit">
                        <div class="flex">
                            <div class="relative" v-if="editingCompany.logo">
                                <img
                                    id="company-logo"
                                    :src="editingCompany.logo"
                                    style="padding: 10px; width: 200px"
                                />
                                <span
                                    class="button__remove-logo"
                                    @click="handleRemove"
                                    ><i class="fas fa-trash"></i
                                ></span>
                            </div>
                        </div>
                        <saving-status-loader
                            :savedStatus="fileSavingStatus"
                            :saving="fileSaving"
                            class="ml-2 justify-self-end"
                        />
                        <p
                            v-if="fileSavingError"
                            class="text-cc-red text-small"
                        >
                            {{ fileSavingError }}
                        </p>
                        <b-form-file
                            @change="fileChange"
                            name="logo"
                            :placeholder="$t('form_select_logo')"
                            accept=".png, .jpg, .jpeg, .gif, .PNG, .JPG, .JPEG, .GIF"
                            drop-placeholder="Drop file here..."
                            v-model="imgFile"
                        ></b-form-file>
                    </template>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import companyApi from "@/api/company.js";
import SavingStatusLoader from "@/components/elements/SavingStatusLoader";
import * as types from "@/store/types.js";
import { countryMapper, getCountrySelectOptions } from "@/util/countries.js";

import { mapState } from "vuex";

export default {
    components: { SavingStatusLoader },
    data() {
        return {
            edit: false,
            editingCompany: {},
            saving: false,
            savingStatus: "waiting",
            fileSaving: false,
            fileSavingStatus: "waiting",
            fileSavingError: null,
            imgFile: null,
        };
    },
    computed: {
        ...mapState({
            company: (state) => state.company,
        }),

        countrySelectOptions() {
            return getCountrySelectOptions();
        },
        countryMapper() {
            return countryMapper;
        },
        companyInitials() {
            if (!this.company || !this.company.name) return "";
            return this.company.name.substring(0, 2);
        },
        companyLogo() {
            if (!this.company || !this.company.logo) return null;
            return this.company.logo;
        },
    },
    methods: {
        openModal() {
            this.$bvModal.show("modal-edit-company");
        },
        handleEdit() {
            this.editingCompany = { ...this.company };
            this.edit = true;
        },
        async handleRemove() {
            try {
                await companyApi.removePhoto();
                this.editingCompany.logo = "";
                this.refreshCompany();
            } catch (error) {
                console.log(error);
            }
        },
        async handleSave() {
            this.saving = true;
            const {
                name,
                sector,
                address_line_1,
                address_line_2,
                postcode,
                city,
                country_code,
            } = this.editingCompany;
            const params = {
                name,
                sector,
                postcode,
                city,
                countryCode: country_code,
                addressLine1: address_line_1,
                addressLine2: address_line_2,
            };

            try {
                await companyApi.updateDetails(params);
                this.refreshCompany();
                this.$amplitude.logEvent("UPDATED_COMPANY");
            } catch (error) {
                console.log(error);
            } finally {
                this.edit = false;
                this.saving = false;
                this.savingStatus = "success";
                this.editingCompany = {};
            }
        },
        async refreshCompany() {
            try {
                const response = await companyApi.getDetails();
                const result = response.data;
                this.$store.dispatch(types.SET_COMPANY, result);
            } catch (error) {
                console.log(error);
            }
        },
        async fileChange(e) {
            // Set loading status
            this.fileSaving = true;
            this.fileSavingStatus = "waiting";
            this.fileSavingError = null;

            // Create a FormData object with the image
            const image = e.target.files[0];
            const formData = new FormData();
            formData.append("image", image);

            try {
                const response = await companyApi.uploadLogo(formData);
                this.fileSavingStatus = "success";
                this.fileSavingError = null;
                this.refreshCompany();
                this.editingCompany.logo = response.data;
            } catch (error) {
                console.log(error.response.data);
                this.fileSavingStatus = "error";
                this.fileSavingError = error.response.data;
            } finally {
                this.fileSaving = false;
            }
        },
    },
};
</script>
